<template>
  <div>
    <v-row>
      <v-col cols="7">
        <label class="primary--text text-body-2 font-weight-bold">Status</label>
      </v-col>
      <v-col cols="1">
        <clear-filter-button @clearFilter="clearFilter"></clear-filter-button>
      </v-col>
    </v-row>
    <form-field
      :field="activationStatusField"
      @fieldChange="updateFilter"
    ></form-field>
  </div>
</template>

<script>
import FormField from "../../objs/Shared/FormField";
import { FieldTypes } from "../../objs/Shared/FieldTypes";
import ClearFilterButton from "../Shared/clear-filter-button.vue";
export default {
  components: {
    ClearFilterButton,
  },
  computed: {
    activationStatusField: function () {
      return new FormField({
        label: "",
        value: this.$store.state.usersModule.activationFilter,
        componentName: FieldTypes.RADIO_GROUP,
        options: ["Active users", "Disabled users"],
      });
    },
  },
  methods: {
    updateFilter: function (event) {
      this.$store.commit("usersModule/setFilter", {
        filterName: "activationFilter",
        value: event,
      });
    },
    clearFilter: function () {
      this.$store.commit("usersModule/clearActivationStatusFilter");
    },
  },
};
</script>
