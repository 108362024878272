<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on }">
        <v-btn
          title="Deactivate user"
          text
          x-small
          v-on="on"
          v-on:click="activateDialog"
          ><icon icon="user-slash" color="primary"
        /></v-btn>
      </template>

      <v-card class="mx-auto primary--text">
        <v-card-title>Deactivate User</v-card-title>

        <v-card-text>
          <p>
            Do you want to deactivate user {{ userName }}? User will no longer
            be able to log into the Omni Portal.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancel">Cancel</v-btn>
          <v-btn color="primary" text @click="disableUser"
            >Deactivate User</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    userName: function () {
      return this.user.userName;
    },
  },
  methods: {
    activateDialog: function () {
      this.$store.commit("usersModule/setUser", this.user);
      this.dialog = true;
    },
    disableUser: async function () {
      var succeeded = await this.$store.dispatch("usersModule/disableUser");
      this.dialog = false;
    },
    cancel: function () {
      this.$store.commit("usersModule/clearUser");
      this.dialog = false;
    },
  },
};
</script>
