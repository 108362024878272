//import store from "../store/index";
import Role from "./Role";

export default class Client {
  clientId?: number | null;
  name?: string | null;
  roles?: Role[] | null;

  public constructor(params: Client = {} as Client) {
    let {
      clientId = null,
      name = null,
      roles = [],
    } = params;

    this.clientId = clientId;
    this.name = name;
    this.roles = roles;
  }
}
