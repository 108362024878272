<template>
  <div>
    <v-row>
      <v-col cols="7">
        <label class="primary--text text-body-1 font-weight-bold"
          >Filter Apps</label
        >
      </v-col>
      <v-col cols="1">
        <clear-filter-button @clearFilter="clearFilters"></clear-filter-button>
      </v-col>
    </v-row>
    <v-spacer class="py-2"></v-spacer>
    <div>
      <v-row>
        <v-col cols="7">
          <label class="primary--text text-body-2 font-weight-bold">Apps</label>
        </v-col>
        <v-col cols="1">
          <clear-filter-button
            @clearFilter="clearClientsFilter"
          ></clear-filter-button>
        </v-col>
      </v-row>
      <v-autocomplete
        :items="clients"
        v-model="selectedClients"
        item-text="name"
        item-value="clientId"
        v-on:change="update"
        multiple
        chips
        dense
        color="primary"
      ></v-autocomplete>
    </div>
    <v-spacer class="py-2"></v-spacer>
    <div>
      <v-row>
        <v-col cols="7">
          <label class="primary--text text-body-2 font-weight-bold"
            >Roles</label
          >
        </v-col>
        <v-col cols="1">
          <clear-filter-button
            @clearFilter="clearRolesFilter"
          ></clear-filter-button>
        </v-col>
      </v-row>
      <v-autocomplete
        :items="roles"
        v-model="selectedRoles"
        item-text="name"
        item-value="id"
        v-on:change="update"
        multiple
        chips
        dense
        color="primary"
      ></v-autocomplete>
    </div>
  </div>
</template>
<script>
import ClearFilterButton from "../Shared/clear-filter-button.vue";
export default {
  components: { ClearFilterButton },
  data: function () {
    return {
      selectedRoles: [],
      selectedClients: [],
    };
  },
  computed: {
    roles() {
      return this.$store.state.clientModule.roles;
    },
    clients() {
      return this.$store.state.clientModule.clients;
    },
  },
  methods: {
    clearRolesFilter() {
      this.selectedRoles = [];

      this.update();
    },
    clearClientsFilter() {
      this.selectedClients = [];

      this.update();
    },
    clearFilters() {
      this.selectedRoles = [];
      this.selectedClients = [];

      this.update();
    },
    update() {
      this.$store.commit("clientModule/filterClients", {
        selectedClients: this.selectedClients,
        selectedRoles: this.selectedRoles,
      });
    },
  },
};
</script>
