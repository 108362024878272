<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on }">
        <v-btn
          title="Activate user account"
          text
          x-small
          v-on="on"
          v-on:click="activateDialog"
          ><icon icon="check" color="primary"
        /></v-btn>
      </template>

      <v-card class="mx-auto primary--text">
        <v-card-title>Activate user account</v-card-title>

        <v-card-text>
          <p>Activate account for {{ user.userName }}?</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancel">Cancel</v-btn>
          <v-btn color="primary" text @click="activate">Activate</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {},
  methods: {
    activateDialog: function () {
      this.$store.commit("usersModule/setUser", this.user);
      this.dialog = true;
    },
    activate: async function () {
      await this.$store.dispatch("usersModule/confirmAccountForUser");
      this.dialog = false;
    },
    cancel: function () {
      this.$store.commit("usersModule/clearUser");
      this.dialog = false;
    },
  },
};
</script>
