import { extend } from "vee-validate";
import {
  required,
  min,
  max,
  min_value,
  max_value,
  alpha,
  numeric,
  alpha_num,
  email,
  confirmed,
} from "vee-validate/dist/rules";
import { formatDate } from "../../utils/format-utils";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("requiredTrue", (value) => {
  if (value === true) {
    return true;
  }

  return "This field is required";
});

extend("requiredValue", (value) => {
  if (Boolean(value)) {
    return true;
  }

  return "This field is required";
});

extend("minLength", {
  ...min,
  params: ["length"],
  message: "This field requires minimum length {length}",
});

extend("maxLength", {
  ...max,
  params: ["length"],
  message: "This field exceeds maximum length {length}",
});

extend("minValue", {
  ...min_value,
  params: ["min"],
  message: "This field requires minimum value {min}",
});

extend("maxValue", {
  ...max_value,
  params: ["max"],
  message: "This field exceeds maximum value {value}",
});

extend("alpha", {
  ...alpha,
  message: "This field must only contain alphabet characters",
});

extend("numeric", {
  ...numeric,
  message: "This field must only contain numeric characters",
});

extend("alphaNum", {
  ...alpha_num,
  message: "This field must only contain letters or numbers",
});

extend("email", {
  ...email,
  message: "This field must be a valid email",
});

extend("alphaExtra", (value) => {
  if (value === "" || /^[-.a-zA-Z\s]+$/g.test(value)) {
    return true;
  }

  return "This field must only contain letters, dashes, or periods";
});

extend("phoneNumber", (value) => {
  if (
    value === "" ||
    /(\([0-9][0-9][0-9]\)\s[0-9][0-9][0-9]\-[0-9][0-9][0-9][0-9])/.test(value)
  ) {
    return true;
  }

  return "This field must be a valid phone number";
});

extend("minDate", {
  params: ["target"],
  validate(value, { target }) {
    let valueDate = new Date(value);
    valueDate.setHours(0, 0, 0, 0);
    let targetDate = new Date(target);
    targetDate.setHours(0, 0, 0, 0);
    return valueDate.getTime() >= targetDate.getTime();
  },
  message: (fieldName, { target }) => {
    return `This field cannot be before ${formatDate(target)}`;
  },
});

extend("maxDate", {
  params: ["target"],
  validate(value, { target }) {
    let valueDate = new Date(value);
    valueDate.setHours(0, 0, 0, 0);
    let targetDate = new Date(target);
    targetDate.setHours(0, 0, 0, 0);
    return valueDate.getTime() < targetDate.getTime();
  },
  message: (fieldName, { target }) => {
    return `This field cannot be on or after ${formatDate(target)}`;
  },
});

extend("ValidFeedbackEmail", (value) => {
  if (value.includes("@") && !value.includes("omniuserportal")) {
    return true;
  }

  return "Please use your personal email address for this communication";
});

extend("DistinctUsername", {
  params: ["target"],
  validate(value, { target }) {
    return !target.includes(value);
  },
  message: "This field requires a distinct username",
});

extend("DistinctEmail", {
  params: ["target"],
  validate(value, { target }) {
    return !target.includes(value);
  },
  message: "This field requires a distinct email",
});

extend("ContainsCapitalLetter", (value) => {
  if (/[A-Z]/.test(value)) {
    return true;
  }

  return "Password must contain at least one capital letter.";
});

extend("ContainsLowercaseLetter", (value) => {
  if (/[a-z]/.test(value)) {
    return true;
  }

  return "Password must contain at least one lowercase letter.";
});

extend("ContainsNumber", (value) => {
  if (/\d/.test(value)) {
    return true;
  }

  return "Password must contain at least one numeric character.";
});

extend("ContainsSpecialCharacter", (value) => {
  if (/[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]/g.test(value)) {
    return true;
  }

  return "Password must contain at least one special character.";
});

extend("ValidPassword", (value) => {
  //ensures that input for password contains
  //one lowercase character
  //one uppercase character
  //one digit
  //one special character
  //at least 4 unique characters (this requirement will be met by extension of meeting the previous 4 requirements)
  //between 8 and 25 characters in length
  if (
    new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,25}$"
    ).test(value)
  ) {
    return true;
  }

  return "Invalid password.";
});

extend("confirmed", {
  ...confirmed,
  params: ["target"],
  message: "This field must match the password",
});
