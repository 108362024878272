import store from "../store/index";

export default class ClientRole {
    clientId?: number | null;
    roleId?: number | null;

    public constructor(params: ClientRole = {} as ClientRole) {
        let {
            clientId = null,
            roleId = null,
        } = params;

        this.clientId = clientId;
        this.roleId = roleId;
    }

    get roleName() {
        return (
            store.getters["clientModule/role"](this.roleId)
        );
    }
}
