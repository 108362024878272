<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on }">
        <v-btn
          title="Send activation email"
          text
          x-small
          v-on="on"
          v-on:click="activateDialog"
        >
          <icon icon="toggle-on" color="primary" />
        </v-btn>
      </template>

      <v-card class="mx-auto primary--text">
        <v-card-title>Send Activation Email</v-card-title>

        <v-card-text>
          <p>
            Do you want to send a welcome activation email to {{ userName }}?
          </p>
          <label v-if="showLastSentDate"
            >Last activation email was sent at: </label
          ><span v-if="showLastSentDate">{{ displayDate }}</span>
          <v-checkbox
            v-if="showLegacyCheckbox"
            v-model="legacyPassword"
            label="Use legacy password"
          ></v-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancel">Cancel</v-btn>
          <v-btn color="primary" text @click="sendWelcomeEmail"
            >Send Email</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      dialog: false,
      legacyPassword: false,
    };
  },
  computed: {
    userName: function () {
      return this.user.userName;
    },
    lastSentDate: function () {
      return new Date(Date.parse(this.user.lastWelcomeEmailSent));
    },
    displayDate: function () {
      return this.lastSentDate.toLocaleDateString();
    },
    showLegacyCheckbox: function () {
      return this.user.legacyPassword != null && this.user.legacyPassword != "";
    },
    showLastSentDate: function () {
      return this.lastSentDate.getFullYear() > 2020;
    },
  },
  methods: {
    activateDialog: function () {
      this.$store.commit("usersModule/setUser", this.user);
      this.dialog = true;
    },
    sendWelcomeEmail: async function () {
      var succeeded = await this.$store.dispatch(
        "usersModule/sendWelcomeEmail",
        this.legacyPassword
      );
      this.legacyPassword = false;
      this.dialog = false;
    },
    cancel: function () {
      this.$store.commit("usersModule/clearUser");
      this.legacyPassword = false;
      this.dialog = false;
    },
  },
};
</script>
