<template>
  <div>
    <v-row>
      <v-col cols="7">
        <label class="primary--text text-body-1 font-weight-bold"
          >Filter Users</label
        >
      </v-col>
      <v-col cols="1">
        <clear-filter-button @clearFilter="clearFilters"></clear-filter-button>
      </v-col>
    </v-row>

    <v-spacer class="py-2"></v-spacer>
    <roles-filter></roles-filter>
    <v-spacer class="py-2"></v-spacer>
    <claims-filter></claims-filter>
    <v-spacer class="py-2"></v-spacer>
    <activation-filter></activation-filter>
    <v-spacer class="py-2"></v-spacer>
    <confirmed-filter></confirmed-filter>
  </div>
</template>

<script>
import RolesFilter from "./roles-filter";
import ClaimsFilter from "./claims-filter";
import ActivationFilter from "./activation-status-filter";
import ConfirmedFilter from "./email-confirmed-filter";
import ClearFilterButton from "../Shared/clear-filter-button.vue";
export default {
  components: {
    RolesFilter,
    ClaimsFilter,
    ActivationFilter,
    ConfirmedFilter,
    ClearFilterButton,
  },
  methods: {
    clearFilters: function () {
      this.$store.commit("usersModule/clearAllFilters");
    },
  },
};
</script>
