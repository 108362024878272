<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="480" scrollable>
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="!newUser"
          title="Edit user information"
          text
          x-small
          v-on="on"
          v-on:click="activateDialog"
        >
          <icon icon="pen" color="primary" />
        </v-btn>
        <v-btn
          v-if="newUser"
          color="primary"
          rounded
          outlined
          v-on="on"
          v-on:click="activateDialog"
        >
          <icon icon="user-plus" color="primary" class="mr-3" /> New User
        </v-btn>
      </template>
      <v-card class="mx-auto primary--text">
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-card-title>User Profile</v-card-title>

          <v-card-text>
            <v-container class="ml-10">
              <v-row dense>
                <v-col cols="5">
                  <form-field
                    :field="fields.firstName"
                    :validations="validations.firstName"
                    @fieldChange="form.firstName = $event"
                  ></form-field>
                </v-col>

                <v-col cols="5">
                  <form-field
                    :field="fields.lastName"
                    :validations="validations.lastName"
                    @fieldChange="form.lastName = $event"
                  ></form-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="5">
                  <form-field
                    :field="fields.userName"
                    :validations="validations.userName"
                    @fieldChange="form.userName = $event"
                  ></form-field>
                </v-col>
                <v-col cols="5">
                  <form-field
                    :field="fields.legacySystemId"
                    @fieldChange="form.legacySystemId = $event"
                  ></form-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="10">
                  <form-field
                    :field="fields.email"
                    :validations="validations.email"
                    @fieldChange="form.email = $event"
                  ></form-field>
                </v-col>
              </v-row>

              <v-row dense v-if="newUser">
                <v-col cols="5">
                  <form-field
                    v-if="newUser"
                    :field="fields.sendWelcomeEmail"
                    @fieldChange="form.sendWelcomeEmail = $event"
                  ></form-field>
                </v-col>

                <v-col cols="5">
                  <form-field
                    v-if="newUser"
                    :field="fields.legacyPassword"
                    @fieldChange="form.legacyPassword = $event"
                  ></form-field>
                </v-col>
              </v-row>

              <v-row dense v-if="!newUser">
                <v-col cols="10">
                  <form-field
                    v-if="!newUser"
                    :field="fields.legacyPassword"
                    @fieldChange="form.legacyPassword = $event"
                  ></form-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="cancel">Cancel</v-btn>
            <v-btn
              color="primary"
              text
              @click="submitUser"
              :dark="!invalid"
              :disabled="invalid"
              >Save</v-btn
            >
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import FormField from "../../objs/Shared/FormField";
import { FieldTypes } from "../../objs/Shared/FieldTypes";
import {
  required,
  email,
  DistinctEmail,
  DistinctUsername,
} from "../../plugins/vee-validate/validation";

export default {
  props: ["user", "newUser"],
  data() {
    return {
      dialog: false,
      form: {},
    };
  },
  computed: {
    fields: function () {
      return {
        firstName: new FormField({
          label: "First name",
          value: this.form.firstName,
          componentName: FieldTypes.TEXT_FIELD,
        }),
        lastName: new FormField({
          label: "Last name",
          value: this.form.lastName,
          componentName: FieldTypes.TEXT_FIELD,
        }),
        legacySystemId: new FormField({
          label: "Legacy system ID",
          value: this.form.legacySystemId,
          componentName: FieldTypes.TEXT_FIELD,
        }),
        userName: new FormField({
          label: "Username",
          value: this.form.userName,
          componentName: FieldTypes.TEXT_FIELD,
        }),
        email: new FormField({
          label: "Email",
          value: this.form.email,
          componentName: FieldTypes.TEXT_FIELD,
        }),
        sendWelcomeEmail: new FormField({
          label: "Send activation email",
          value: this.form.sendWelcomeEmail,
          componentName: FieldTypes.CHECKBOX,
        }),
        legacyPassword: new FormField({
          label: "Legacy password",
          value: this.form.legacyPassword,
          componentName: FieldTypes.TEXT_FIELD,
        }),
      };
    },
    allUsernames: function () {
      return this.newUser
        ? this.$store.getters["usersModule/getAllUsernames"]
        : this.$store.getters["usersModule/getAllOtherUsernames"](this.user);
    },
    allEmails: function () {
      return this.newUser
        ? this.$store.getters["usersModule/getAllEmails"]
        : this.$store.getters["usersModule/getAllOtherEmails"](this.user);
    },
    validations: function () {
      return {
        userName: {
          required: true,
          DistinctUsername: [this.allUsernames],
        },
        firstName: { required: true },
        lastName: { required: true },
        email: {
          email: true,
          required: true,
          DistinctEmail: [this.allEmails],
        },
      };
    },
  },
  methods: {
    activateDialog: function () {
      if (this.newUser) {
        this.$store.commit("usersModule/clearUser");
      } else {
        this.$store.commit("usersModule/setUser", this.user);
      }
      this.form = this.$store.state.usersModule.user;
      this.dialog = true;
    },
    submitUser: function () {
      this.$store.commit("usersModule/setUser", this.form);
      if (this.newUser) {
        this.$store.dispatch("usersModule/createUser");
      } else {
        this.$store.dispatch("usersModule/updateUser");
      }
      this.dialog = false;
    },
    cancel: function () {
      this.$refs.form.reset();
      this.$store.commit("usersModule/clearUser");
      this.dialog = false;
    },
  },
};
</script>
