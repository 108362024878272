<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on }">
        <v-btn
          title="Reset user password"
          text
          x-small
          v-on="on"
          v-on:click="activateDialog"
          ><icon icon="lock" color="primary"
        /></v-btn>
      </template>

      <v-card class="mx-auto primary--text">
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-card-title>Reset User Password</v-card-title>

          <v-card-text>
            <v-container class="ml-10">
              <v-row dense>
                <v-col cols="8">
                  <validation-provider
                    :rules="validations.password"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="password"
                      label="Enter a new password"
                      :error-messages="errors"
                      color="primary"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="8">
                  <validation-provider
                    :rules="validations.passwordConfirmation"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="passwordConfirmation"
                      label="Confirm password"
                      :error-messages="errors"
                      color="primary"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="cancel">Cancel</v-btn>
            <v-btn
              color="primary"
              text
              :disabled="invalid"
              :dark="!invalid"
              @click="resetPassword"
              >Reset Password</v-btn
            >
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  required,
  minLength,
  ContainsCapitalLetter,
  ContainsLowercaseLetter,
  ContainsNumber,
  ContainsSpecialCharacter,
  confirmed,
} from "../../plugins/vee-validate/validation";

export default {
  props: ["user", "newUser"],
  data() {
    return {
      dialog: false,
      password: "",
      passwordConfirmation: "",
    };
  },
  computed: {
    validations: function () {
      return {
        password: {
          required: true,
          minLength: 8,
          ContainsCapitalLetter: true,
          ContainsLowercaseLetter: true,
          ContainsNumber: true,
          ContainsSpecialCharacter: true,
        },
        passwordConfirmation: {
          required: true,
          confirmed: this.password,
        },
      };
    },
  },
  methods: {
    activateDialog: function () {
      this.$store.commit("usersModule/setUser", this.user);
      this.password = this.$store.state.usersModule.user.password;
      this.dialog = true;
    },
    resetPassword: function () {
      this.$store.commit("usersModule/setUserPassword", this.password);
      this.$store.dispatch("usersModule/resetPassword");
      this.dialog = false;
    },
    cancel: function () {
      this.passwordConfirmation = "";
      this.$store.commit("usersModule/clearUser");
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
</script>
