export default class User {
  firstName?: string | null;
  lastName?: string | null;
  legacySystemId?: string | null;
  isEnabled?: boolean | null;
  userName?: string | null;
  legacyPassword?: string | null;
  password?: string | null;
  passwordConfirmation?: string | null;
  email?: string | null;
  id?: string | null;
  claims?: object[];
  roles?: string[];
  emailConfirmed?: boolean | null;
  lastWelcomeEmailSent?: Date | null;

  public constructor(params: User = {} as User) {
    let {
      firstName = "",
      lastName = "",
      legacySystemId = "",
      isEnabled = true,
      userName = "",
      legacyPassword = "",
      password = "",
      passwordConfirmation = "",
      email = "",
      id = "",
      claims = [],
      roles = [],
      emailConfirmed = false,
      lastWelcomeEmailSent = new Date("01/01/0001"),
    } = params;

    this.firstName = firstName;
    this.lastName = lastName;
    this.legacySystemId = legacySystemId;
    this.isEnabled = isEnabled;
    this.userName = userName;
    this.legacyPassword = legacyPassword;
    this.password = password;
    this.password = passwordConfirmation;
    this.email = email;
    this.id = id;
    this.claims = claims;
    this.roles = roles;
    this.emailConfirmed = emailConfirmed;
    this.lastWelcomeEmailSent = lastWelcomeEmailSent;
  }
}
