<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <v-select
      :label="field.label"
      :value="value"
      :disabled="readonly"
      :items="field.options"
      :item-text="field.itemText"
      return-object
      multiple
      chips
      deletable-chips
      @change="onChange"
      :error-messages="errors"
      color="#005c7b"
    ></v-select>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "validations"],
  methods: {
    onChange(event) {
      this.$emit("fieldChange", event);
    }
  }
};
</script>
