<template>
    <v-dialog v-model="dialog" persistent width="500">
        <v-card class="mx-auto primary--text">
            <v-card-title>{{ title }}</v-card-title>
            <v-card-text class="body-1 font-weight-medium">{{ message }}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark text @click="cancel">Cancel</v-btn>
                <v-btn color="primary" dark outlined @click="save">{{
                    okButtonText
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            message: "",
            title: "",
            okButtonText: "OK",
        };
    },
    methods: {
        open(title, message, okButtonText) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.okButtonText = okButtonText || "OK";
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        save() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        },
    },
};
</script>