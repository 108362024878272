<template>
  <div>
    <v-row>
      <v-col cols="7">
        <label class="primary--text text-body-2 font-weight-bold"
          >User Claims</label
        >
      </v-col>
      <v-col cols="1">
        <clear-filter-button
          @clearFilter="clearClaimsFilter"
        ></clear-filter-button>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10">
        <form-field
          :field="claimTypeField"
          @fieldChange="updateClaimTypeFilter"
        ></form-field>
        <form-field
          :field="claimValueField"
          @fieldChange="updateClaimValueFilter"
        ></form-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormField from "../../objs/Shared/FormField";
import { FieldTypes } from "../../objs/Shared/FieldTypes";
import ClearFilterButton from "../Shared/clear-filter-button.vue";
export default {
  components: {
    ClearFilterButton,
  },
  data: function () {
    return {
      selected: [],
    };
  },
  computed: {
    claimTypeField: function () {
      return new FormField({
        label: "Claim Type",
        value: this.$store.state.usersModule.claimType,
        componentName: FieldTypes.STANDARD_SELECT,
        options: this.$store.state.usersModule.claimTypes,
      });
    },
    claimValueField: function () {
      return new FormField({
        label: "Claim Value",
        value: this.$store.state.usersModule.claimValue,
        componentName: FieldTypes.STANDARD_SELECT,
        options: this.valueOptions,
      });
    },
    valueOptions: function () {
      return this.$store.getters["usersModule/getClaimValues"];
    },
  },
  methods: {
    updateClaimTypeFilter: function (event) {
      this.$store.commit("usersModule/setFilter", {
        filterName: "claimType",
        value: event,
      });
    },
    updateClaimValueFilter: function (event) {
      this.$store.commit("usersModule/setFilter", {
        filterName: "claimValue",
        value: event,
      });
    },
    clearClaimsFilter: function () {
      this.$store.commit("usersModule/clearClaimsFilter");
    },
  },
};
</script>
