import * as api from "../Services/api";
import Client from "../../objs/Client";
import ClientRole from "../../objs/ClientRole";
import Role from "../../objs/Role";

export const state = {
  clients: [],
  roles: [],
  filteredClients: [],
};

export const mutations = {
  ADD_CLIENT_ROLE(state, clientRole) {
    var cr = new ClientRole(clientRole);
    var client = state.clients.find((c) => c.clientId === cr.clientId);

    client.roles.push(new Role({ id: cr.roleId, name: cr.roleName }));
  },

  SET_CLIENTS(state, clients) {
    state.clients = clients
      .map((item) => new Client(item))
      .sort((a, b) => a.name.localeCompare(b.name));
  },

  SET_DEFAULT_FILTERED_CLIENTS(state) {
    state.filteredClients = state.clients;
  },

  SET_ROLES(state, roles) {
    state.roles = roles
      .map((item) => new Role(item))
      .sort((a, b) => a.name.localeCompare(b.name));
  },

  DELETE_CLIENT_ROLE(state, payload) {
    var client = state.clients.find((c) => c.clientId === payload.clientId);

    const findIndex = client.roles.findIndex(({ id }) => id === payload.roleId);
    client.roles.splice(findIndex, 1);
  },

  filterClients(state, payload) {
    //Reset Filtered list each time filter is updated
    state.filteredClients = state.clients;

    var clients = payload.selectedClients;
    var roles = payload.selectedRoles;

    if (roles.length > 0) {
      state.filteredClients = state.filteredClients.filter(function (c) {
        var clientRoleIds = c.roles.map((r) => r.id);
        return roles.some((r) => clientRoleIds.includes(r));
      });
    }

    if (clients.length > 0) {
      state.filteredClients = state.filteredClients.filter(function (cr) {
        return clients.includes(cr.clientId);
      });
    }
  },
};

export const actions = {
  async createClientRole({ commit }, data) {
    return api.createClientRole(data).then((response) => {
      if (response.success) {
        commit("ADD_CLIENT_ROLE", response.data);
      }
      return response;
    });
  },

  async getClients({ commit }) {
    return api.getClients().then((response) => {
      if (response.success) {
        commit("SET_CLIENTS", response.data);
      }
    });
  },

  async getRoles({ commit }) {
    return api.getFullRoles().then((response) => {
      if (response.success) {
        commit("SET_ROLES", response.data);
      }
    });
  },

  async deleteClientRole({ commit }, data) {
    return api
      .deleteClientRole(
        new ClientRole({ clientId: data.clientId, roleId: data.roleId })
      )
      .then((response) => {
        if (response.success) {
          commit("DELETE_CLIENT_ROLE", data);
        }
        return response.success;
      });
  },

  setDefaultFilteredClients({ commit }) {
    commit("SET_DEFAULT_FILTERED_CLIENTS");
  },
};

export const getters = {
  role: (state) => (roleId) => {
    return state.roles.find((item) => item.id == roleId)?.name;
  },
  client: (state) => (clientId) => {
    return state.clients.find((item) => item.clientId == clientId);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
