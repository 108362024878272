import User from "../../objs/User";
import * as api from "../Services/api";

export const state = {
  user: new User(),
  users: [],
  roles: [],
  claimsDic: {},
  claimTypes: [],
  valueTypes: ["Integer", "Double", "String", "Date", "Time", "Boolean"],

  //Filters
  selectedRoles: [],
  activationFilter: "",
  claimType: "",
  claimValue: "",
  clearUserRoles: 0,
  confirmedFilter: "",
};

export const mutations = {
  clearUser(state) {
    state.user.userName = "";
    state.user.password = "";
    state.user.email = "";
    state.user.id = "";
    state.user.firstName = "";
    state.user.lastName = "";
    state.user.legacySystemId = "";
    state.user.legacyPassword = "";
    state.user.roles = [];
    state.user.claims = [];
    state.user.lastWelcomeEmailSent = new Date("01/01/0001");
  },

  clearAllFilters(state) {
    state.selectedRoles = [];
    state.claimType = "";
    state.claimValue = "";
    state.activationFilter = "";
    state.confirmedFilter = "";
    state.clearUserRoles = state.clearUserRoles + 1;
  },

  setFilter(state, payload) {
    state[payload.filterName] = payload.value;
  },

  setUsers(state, users) {
    state.users = users.sort((a, b) => a.lastName.localeCompare(b.lastName));
  },

  setRoles(state, roles) {
    state.roles = roles.sort((a, b) => a.localeCompare(b));
  },

  setClaimsDic(state, claimsDic) {
    state.claimsDic = claimsDic;
    state.claimTypes = Object.keys(claimsDic);
  },

  disableUser(state) {
    state.user.isEnabled = false;
  },
  enableUser(state) {
    state.user.isEnabled = true;
  },
  setUser(state, user) {
    state.user.userName = user.userName;
    state.user.firstName = user.firstName;
    state.user.lastName = user.lastName;
    state.user.email = user.email;
    state.user.id = user.id;
    state.user.legacySystemId = user.legacySystemId;
    state.user.legacyPassword = user.legacyPassword;
    state.lastWelcomeEmailSent = user.lastWelcomeEmailSent;
    state.user.isEnabled = user.isEnabled;
    state.user.roles = [...user.roles];
    state.user.claims = [...user.claims];
  },
  setUserRoles(state, roles) {
    state.user.roles = [...roles];
  },
  setUserPassword(state, password) {
    state.user.password = password;
  },

  clearClaimsFilter(state) {
    state.claimType = "";
    state.claimValue = "";
  },

  clearRolesFilter(state) {
    state.selectedRoles = [];
  },

  clearActivationStatusFilter(state) {
    state.activationFilter = "";
  },

  clearConfirmedAccountFilter(state) {
    state.confirmedFilter = "";
  },

  setUserAtIndex(state, user) {
    var index = state.users.findIndex((u) => u.id == user.id);
    state.users[index].userName = user.userName;
    state.users[index].firstName = user.firstName;
    state.users[index].lastName = user.lastName;
    state.users[index].email = user.email;
    state.users[index].id = user.id;
    state.users[index].legacyPassword = user.legacyPassword;
    state.users[index].legacySystemId = user.legacySystemId;
    state.users[index].emailConfirmed = user.emailConfirmed;
    state.users[index].lastWelcomeEmailSent = user.lastWelcomeEmailSent;
    state.users[index].isEnabled = user.isEnabled;
    state.users[index].roles = [...user.roles];
    state.users[index].claims = [...user.claims];
  },
};

export const getters = {
  getFilteredUsers: (state) => {
    var filteredUsers = state.users;

    //Filter by role
    if (state.selectedRoles.length > 0) {
      filteredUsers = filteredUsers.filter(function (user) {
        var userRoles = user.roles;
        var found = userRoles.some((r) => state.selectedRoles.includes(r));
        return found;
      });
    }

    //Filter by claim
    if (state.claimType != "") {
      filteredUsers = filteredUsers.filter(function (user) {
        var userClaims = user.claims;
        var matchingClaims = userClaims.filter(function (claim) {
          return claim.claimType == state.claimType;
        });
        if (matchingClaims.length > 0) {
          if (state.claimValue == "Any value" || state.claimValue == "") {
            return true;
          } else {
            var matchingValues = matchingClaims.filter(function (claim) {
              return state.claimValue == claim.claimValue;
            });
            if (matchingValues.length > 0) {
              return true;
            } else {
              return false;
            }
          }
        } else {
          return false;
        }
      });
    }

    //Filter by activation status
    if (state.activationFilter == "Active users") {
      filteredUsers = filteredUsers.filter(function (user) {
        return user.isEnabled == true;
      });
    } else if (state.activationFilter == "Disabled users") {
      filteredUsers = filteredUsers.filter(function (user) {
        return user.isEnabled == false;
      });
    }

    //Filter by confirmed status
    if (state.confirmedFilter == "Confirmed") {
      filteredUsers = filteredUsers.filter(function (user) {
        return user.emailConfirmed == true;
      });
    } else if (state.confirmedFilter == "Unconfirmed") {
      filteredUsers = filteredUsers.filter(function (user) {
        return user.emailConfirmed == false;
      });
    }

    return filteredUsers;
  },

  getClaimValues: (state) => {
    if (state.claimType == "") {
      return [];
    } else {
      return state.claimsDic[state.claimType];
    }
  },

  getAllUsernames: (state) => {
    return state.users.map((i) => i.userName.toLowerCase());
  },

  getAllEmails: (state) => {
    return state.users.map((i) => i.email.toLowerCase());
  },

  getAllOtherUsernames: (state) => (user) => {
    return state.users.map((i) => {
      if (i.id != user.id) {
        return i.userName.toLowerCase();
      }
    });
  },

  getAllOtherEmails: (state) => (user) => {
    return state.users.map((i) => {
      if (i.id != user.id) {
        return i.email.toLowerCase();
      }
    });
  },
};

export const actions = {
  //Retrieve users, claim types, and role names
  async getUsers({ commit }) {
    var response = await api.getUsers();
    if (response) {
      commit("setUsers", response.data);
      return "Success";
    }
  },

  async getRoles({ commit }) {
    var response = await api.getRoles();
    commit("setRoles", response.data);
  },

  async getClaimsDic({ commit }) {
    var response = await api.getClaimsDic();
    commit("setClaimsDic", response.data);
  },

  //Get an individual user
  async getUser({}, user) {
    await api.getUser(user.id);
  },

  async createUser({ commit, state }) {
    var msg = "";
    var created = await api.createUser(state.user);
    if (created.success) {
      //Now create the messaging user
      var user = created.data;
      var msgingCreated = await api.createNewMessagingUser({
        omniUserId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      });
      if (msgingCreated.success) {
        msg = "User and messaging accounts created successfully.";
      } else {
        msg =
          "User account was created successfully. However, there was an error creating this messaging account. Please contact support.";
      }
      commit("clearUser");
    } else {
      msg = "There was an error creating this user.";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, { root: true });
    var userList = await api.getUsers();
    if (userList.success) {
      //TODO: Get the individual user and replace in state, rather than getting all users!
      commit("setUsers", userList.data);
    }
  },

  async updateUser({ commit, state }) {
    var msg = "";
    state.user.password = "";
    state.user.claims = [];
    var updated = await api.updateUser(state.user);
    if (updated.success) {
      var user = await api.getUser(state.user.id);
      if (user.success) {
        commit("setUserAtIndex", user.data);
      }
      commit("clearUser");
      msg = "User updated successfully.";
    } else {
      msg = "There was an error updating this user.";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, { root: true });
  },

  async updateUserRoles({ commit, state }) {
    state.user.claims = [];

    var msg = "";
    var updated = await api.updateUserRoles(state.user);
    if (updated.success) {
      var user = await api.getUser(state.user.id);
      if (user.success) {
        commit("setUserAtIndex", user.data);
      }
      commit("clearUser");
      msg = "User roles updated successfully.";
    } else {
      msg = "There was an error updating this user's roles.";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, { root: true });
  },

  async confirmAccountForUser({ commit, state }) {
    var msg = "";
    var updated = await api.confirmAccountForUser(state.user.id);
    if (updated.success) {
      var user = await api.getUser(state.user.id);
      if (user.success) {
        commit("setUserAtIndex", user.data);
      }
      commit("clearUser");
      msg = "User account confirmed.";
    } else {
      msg = "There was an error confirming this user's account.";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, { root: true });
  },

  async disableUser({ commit, state }) {
    var msg = "";
    var updated = await api.lockoutUser(state.user.id);
    if (updated.success) {
      var msgingDisabled = await api.disableMessagingUser(state.user.id);
      if (msgingDisabled) {
        var user = await api.getUser(state.user.id);
        if (user.success) {
          commit("setUserAtIndex", user.data);
        }
        commit("clearUser");
        msg = "User disabled successfully.";
      } else {
        msg = "There was an error disabling this user's messaging account.";
      }
    } else {
      msg = "There was an error disabling this user.";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, { root: true });
  },

  async enableUser({ commit, state }) {
    var msg = "";
    var updated = await api.endLockout(state.user.id);
    if (updated.success) {
      var msgingEnabled = await api.enableMessagingUser(state.user.id);
      if (msgingEnabled) {
        var user = await api.getUser(state.user.id);
        if (user.success) {
          commit("setUserAtIndex", user.data);
        }
        commit("clearUser");
        msg = "User reactivated successfully.";
      } else {
        msg = "There was an error reactivating this user's messaging account.";
      }
    } else {
      msg = "There was an error reactivating this user.";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, { root: true });
  },

  async sendWelcomeEmail({ commit, state }, payload) {
    var msg = "";
    var updated = await api.sendWelcomeEmail({
      userId: state.user.id,
      legacyPassword: payload,
    });
    if (updated.success) {
      var user = await api.getUser(state.user.id);
      if (user.success) {
        commit("setUserAtIndex", user.data);
      }
      commit("clearUser");
      msg = "Welcome email sent.";
    } else {
      msg = "There was an error sending this welcome email.";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, { root: true });
  },

  async resetPassword({ commit, state }) {
    state.user.claims = [];

    var msg = "";
    var updated = await api.resetPassword(state.user);
    if (updated.success) {
      commit("clearUser");
      msg = "Password reset successfully.";
    } else {
      msg = "There was an error resetting this password.";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, { root: true });
  },

  async resetPasswordFromLink({ commit }, payload) {
    var msg = "";
    var updated = await api.resetPasswordFromLink(payload);
    if (updated.success) {
      msg = "Password reset successfully.";
    } else {
      msg = "There was an error resetting this password.";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, { root: true });
  },

  async sendPasswordResetEmail({ commit, state }) {
    state.user.claims = [];

    var msg = "";
    var updated = await api.sendPasswordResetEmail(state.user);
    if (updated.success) {
      commit("clearUser");
      msg = "Password reset email sent.";
    } else {
      msg = "There was an error sending this email.";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, { root: true });
  },

  async createRole({ commit }, roleName) {
    var msg = "";
    var updated = await api.createRole({ name: roleName });
    if (updated.success) {
      msg = "Role created successfully.";
    } else {
      msg = "There was an error creating this role.";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, { root: true });
    var roleList = await api.getRoles();
    if (roleList.success) {
      commit("setRoles", roleList.data);
    }
  },

  async createUserClaim({ commit, state }, data) {
    var msg = "";
    var created = await api.createUserClaim(data);
    if (created.success) {
      var user = await api.getUser(data.userId);
      if (user.success) {
        commit("setUserAtIndex", user.data);
      }
      msg = "UserClaim created successfully.";
      if (
        !state.claimTypes.includes(data.claimType) ||
        !state.claimsDic[data.claimType].includes(data.claimValue)
      ) {
        var response = await api.getClaimsDic();
        commit("setClaimsDic", response.data);
      }
    } else {
      msg = "There was an error creating this UserClaim.";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, { root: true });
    var userList = await api.getUsers();
    if (userList.success) {
      commit("setUsers", userList.data);
    }
  },

  async deleteUserClaim({ commit }, data) {
    var msg = "";
    var created = await api.deleteUserClaim(data);
    if (created.success) {
      var user = await api.getUser(data.userId);
      if (user.success) {
        commit("setUserAtIndex", user.data);
        //Update claims dic
        var response = await api.getClaimsDic();
        commit("setClaimsDic", response.data);
      }
      msg = "UserClaim deleted successfully.";
    } else {
      msg = "There was an error deleting this UserClaim.";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, { root: true });
    var userList = await api.getUsers();
    if (userList.success) {
      commit("setUsers", userList.data);
    }
  },

  async deleteUser({ commit, state }) {
    await api.deleteUserById(state.user).then(
      (response) =>
        async function () {
          commit("clearUser");
          await api.getUsers().then((response) => {
            commit("setUsers", response.data);
          });
        }
    );
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
