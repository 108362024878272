import axios from "axios";
var omniApiUrlPrefix = process.env.VUE_APP_OMNI_BASE_URL;
var urlPrefix = process.env.VUE_APP_OMNI_SECURITY_BASE_URL;

export async function sendFeedbackEmail(data) {
  return axios
    .post(omniApiUrlPrefix + "/api/shared/sendFeedbackEmail", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getUsers() {
  return axios
    .get(urlPrefix + "/api/identity/getUsers")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function getUserAccess(data) {
  return axios
    .get(urlPrefix + "/api/identity/getUserAccess", {
      params: {
        clientId: data.clientId,
        userId: data.userId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function getRoles() {
  return axios
    .get(urlPrefix + "/api/identity/getRoles")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function getClaimTypes() {
  return axios
    .get(urlPrefix + "/api/identity/getClaimTypes")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function getClaimsDic() {
  return axios
    .get(urlPrefix + "/api/identity/getClaimsDictionary")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function getUser(data) {
  return axios
    .get(urlPrefix + "/api/identity/getUser", {
      params: {
        userId: data,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function createNewMessagingUser(payload) {
  return axios
    .post(
      omniApiUrlPrefix + "/api/messaging/createMessagingUserForNewUser",
      null,
      {
        params: {
          omniUserId: payload.omniUserId,
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function enableMessagingUser(userId) {
  return axios
    .post(
      omniApiUrlPrefix + "/api/messaging/updateMessagingUserActivationStatus",
      null,
      {
        params: {
          userId: userId,
          isActive: true,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function disableMessagingUser(userId) {
  return axios
    .post(
      omniApiUrlPrefix + "/api/messaging/updateMessagingUserActivationStatus",
      null,
      {
        params: {
          userId: userId,
          isActive: false,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function lockoutUser(payload) {
  return axios
    .post(urlPrefix + "/api/identity/lockoutUser", null, {
      params: {
        userId: payload,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function confirmAccountForUser(payload) {
  return axios
    .post(urlPrefix + "/api/identity/confirmAccountForUser", null, {
      params: {
        userId: payload,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function sendWelcomeEmail(payload) {
  return axios
    .post(urlPrefix + "/api/identity/sendWelcomeEmail", null, {
      params: {
        userId: payload.userId,
        legacyPassword: payload.legacyPassword,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function endLockout(payload) {
  return axios
    .post(urlPrefix + "/api/identity/endLockout", null, {
      params: {
        userId: payload,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function createUser(data) {
  return axios
    .post(urlPrefix + "/api/identity/createNewUser", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function updateUser(data) {
  return axios
    .post(urlPrefix + "/api/identity/updateUserSansPassword", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function createUserClaim(data) {
  return axios
    .post(urlPrefix + "/api/identity/createUserClaim", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function deleteUserClaim(data) {
  return axios
    .post(urlPrefix + "/api/identity/deleteUserClaim", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function updateUserRoles(data) {
  return axios
    .post(urlPrefix + "/api/identity/updateUserRoles", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function createRole(data) {
  return axios
    .post(urlPrefix + "/api/identity/createRole", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function resetPassword(data) {
  return axios
    .post(urlPrefix + "/api/identity/resetPassword", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function resetPasswordFromLink(data) {
  return axios
    .post(urlPrefix + "/api/identity/resetPasswordWithToken", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function sendPasswordResetEmail(data) {
  return axios
    .post(urlPrefix + "/api/identity/sendPasswordResetEmail", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function deleteUserById(data) {
  axios
    .post(urlPrefix + "/api/identity/deleteUserById", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export function createClientRole(data) {
  return axios
    .post(urlPrefix + "/api/identity/createClientRole", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function getClients() {
  return axios
    .get(urlPrefix + "/api/identity/getClients")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function deleteClientRole(data) {
  return axios
    .post(urlPrefix + "/api/identity/deleteClientRole", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function getFullRoles() {
  return axios
    .get(urlPrefix + "/api/identity/getFullRoles")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}
