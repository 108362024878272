<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on }">
        <v-btn
          title="Manage user claims"
          text
          x-small
          v-on="on"
          v-on:click="activateDialog"
          ><icon icon="user-tag" color="primary"
        /></v-btn>
      </template>

      <v-card class="mx-auto primary--text">
        <v-card-title>Manage User Claims</v-card-title>

        <v-card-text>
          <claims-table :user="user"></claims-table>
          <new-claim-modal :user="user"></new-claim-modal>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import NewClaimModal from "./new-claim-modal";
import ClaimsTable from "./claims-table";
export default {
  components: {
    ClaimsTable,
    NewClaimModal,
  },
  props: ["user"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {},
  methods: {
    activateDialog: function () {
      this.$store.commit("usersModule/setUser", this.user);
      this.dialog = true;
    },

    save: function () {
      this.$store.commit("usersModule/clearUser");
      this.dialog = false;
    },
  },
};
</script>
