<template>
  <v-card min-width="1100px" class="ml-0 pl-0">
    <div id="wrapper">
      <div id="leftbox">
        <v-card-title> </v-card-title>
        <filter-apps-panel class="ml-8 pb-10"></filter-apps-panel>
      </div>
      <v-divider vertical></v-divider>
      <div id="rightbox">
        <v-spacer class="py-3"></v-spacer>
        <v-data-table
          :headers="headers"
          :items="filteredClients"
          :key="filteredClients.length"
          :loading="loading"
          loading-text="Loading... Please wait"
          sort-by="name"
        >
          <template v-slot:[`item.role`]="{ item }">
            <v-chip
              color="primary"
              v-for="(role, ind) in item.roles"
              :key="ind"
              close
              outlined
              class="ma-2"
              @click:close="deleteClientRole(item, role.id)"
            >
              {{ role.name }}
            </v-chip>
            <confirm-modal ref="confirm"></confirm-modal>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <new-role-modal
              :clientId="item.clientId"
              class="mr-1 mt-0"
            ></new-role-modal>
          </template>
        </v-data-table>
      </div>
    </div>
  </v-card>
</template>
<script>
import NewRoleModal from "../ClientManager/new-role-modal";
import FilterAppsPanel from "../ClientManager/filter-apps-panel";
import ConfirmModal from "../Shared/confirm-modal.vue";

export default {
  components: {
    NewRoleModal,
    FilterAppsPanel,
    ConfirmModal,
  },
  data: function () {
    return {
      loading: false,
    };
  },

  computed: {
    headers() {
      return [
        { text: "App", align: "left", value: "name" },
        { text: "Role", align: "left", value: "role" },
        { text: "Actions", sortable: false, value: "actions" },
      ];
    },
    filteredClients() {
      return this.$store.state.clientModule.filteredClients;
    },
  },
  mounted: async function () {
    this.loading = true;
    await this.$store.dispatch("clientModule/getClients");
    this.loading = false;

    this.$store.dispatch("clientModule/getRoles");
    this.$store.dispatch("clientModule/setDefaultFilteredClients");
  },

  methods: {
    async deleteClientRole(client, roleId) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to remove this role from the " +
            client.name +
            " app?"
        )
      ) {
        const success = await this.$store.dispatch(
          "clientModule/deleteClientRole",
          { clientId: client.clientId, roleId: roleId }
        );

        if (success) {
          this.$store.commit(
            "uxModule/setSnackbarMsg",
            "Successfully removed this role from the " + client.name + " app"
          );
        } else {
          this.$store.commit(
            "uxModule/setSnackbarMsg",
            "Error removing this role from the " + client.name + " app"
          );
        }

        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
