export default class Role {
    id?: number | null;
    name?: string | null;

    public constructor(params: Role = {} as Role) {
        let {
            id = null,
            name = null,
        } = params;

        this.id = id;
        this.name = name;
    }

    // get roleName() {
    //   return (
    //     store.getters["clientModule/role"](this.roleId)
    //   );
    // }
}
