<template>
  <v-card min-width="1100px" class="ml-0 pl-0">
    <div id="wrapper">
      <div id="leftbox">
        <v-card-title> </v-card-title>
        <filter-panel class="ml-8 pb-10"></filter-panel>
      </div>
      <v-divider vertical></v-divider>
      <div id="rightbox">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            color="primary"
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :loading="loading"
          loading-text="Loading... Please wait"
          :items="users"
          item-key="id"
          :search="search"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-row>
              <edit-user-modal :user="item" class="mr-1 mt-0"></edit-user-modal>
              <reset-password-modal
                :user="item"
                class="mr-1 mt-0"
              ></reset-password-modal>
              <send-reset-link-modal
                :user="item"
                class="mr-1 mt-0"
              ></send-reset-link-modal>
              <roles-modal :user="item" class="mr-1 mt-0"></roles-modal>
              <claims-modal :user="item" class="mr-1 mt-0"></claims-modal>
              <send-welcome-email-modal
                :user="item"
                class="mr-1 mt-0"
              ></send-welcome-email-modal>
              <activate-user-modal
                :user="item"
                class="mr-1 mt-0"
              ></activate-user-modal>
              <disable-user-modal
                v-if="item.isEnabled"
                :user="item"
                class="mr-1 mt-0"
              ></disable-user-modal>
              <enable-user-modal
                v-if="!item.isEnabled"
                :user="item"
                class="mr-1 mt-0"
              ></enable-user-modal>
            </v-row>
          </template>
        </v-data-table>
        <edit-user-modal :newUser="true" class="pb-6"></edit-user-modal>
      </div>
    </div>
  </v-card>
</template>

<script>
import EditUserModal from "./edit-user-info-modal";
import ResetPasswordModal from "./reset-password-modal";
import SendResetLinkModal from "./send-reset-link-modal";
import DisableUserModal from "./disable-user-modal";
import SendWelcomeEmailModal from "./send-welcome-email-modal";
import EnableUserModal from "./enable-user-modal";
import RolesModal from "./manage-user-roles-modal";
import ClaimsModal from "./manage-user-claims-modal";
import ActivateUserModal from "./activate-user-modal";
import FilterPanel from "./filter-panel";

export default {
  data: function () {
    return {
      search: "",
      loading: false,
    };
  },
  components: {
    EditUserModal,
    ResetPasswordModal,
    SendResetLinkModal,
    DisableUserModal,
    EnableUserModal,
    RolesModal,
    ClaimsModal,
    FilterPanel,
    SendWelcomeEmailModal,
    ActivateUserModal,
  },

  computed: {
    headers() {
      return [
        {
          text: "First name",
          align: "left",
          value: "firstName",
        },
        {
          text: "Last name",
          align: "left",
          value: "lastName",
        },
        {
          text: "Username",
          align: "left",
          value: "userName",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
        },
      ];
    },
    users: function () {
      return this.$store.getters["usersModule/getFilteredUsers"];
    },
  },
  mounted: async function () {
    this.loading = true;
    await this.$store.dispatch("usersModule/getUsers");
    this.loading = false;
    this.$store.dispatch("usersModule/getClaimsDic");
    this.$store.dispatch("usersModule/getRoles");
  },
  methods: {},
};
</script>

<style>
#wrapper {
  display: flex;
}

#leftbox {
  flex: 0 0 25%;
  margin-right: 20px;
}

#rightbox {
  flex: 1;
  margin-left: 20px;
}
</style>
