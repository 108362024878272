<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on }">
        <v-btn title="New claim" text v-on="on" v-on:click="activateDialog"
          ><icon icon="plus-circle" color="primary"
        /></v-btn>
      </template>

      <v-card class="mx-auto primary--text">
        <v-card-title>Add User Claim</v-card-title>

        <v-card-text>
          <form-field
            :field="claimTypeField"
            @fieldChange="setClaimType"
          ></form-field>
          <form-field
            :field="claimValueField"
            @fieldChange="setClaimValue"
          ></form-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancel">Cancel</v-btn>
          <v-btn color="primary" text @click="createUserClaim"
            >Create Claim</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import FormField from "../../objs/Shared/FormField";
import { FieldTypes } from "../../objs/Shared/FieldTypes";
import ClaimsTable from "./claims-table";
export default {
  components: {
    ClaimsTable,
  },
  props: ["user"],
  data() {
    return {
      dialog: false,
      claimType: "",
      claimValue: "",
    };
  },
  computed: {
    claimTypeField: function () {
      return new FormField({
        label: "Claim Type",
        value: this.claimType,
        componentName: FieldTypes.COMBOBOX,
        options: this.$store.state.usersModule.claimTypes,
      });
    },
    claimValueField: function () {
      return new FormField({
        label: "Claim Value",
        value: this.claimValue,
        componentName: FieldTypes.TEXT_FIELD,
      });
    },
  },
  methods: {
    setClaimType(event) {
      this.claimType = event;
    },
    setClaimValue(event) {
      this.claimValue = event;
    },
    activateDialog: function () {
      this.$store.commit("usersModule/setUser", this.user);
      this.dialog = true;
    },
    createUserClaim: async function () {
      var succeeded = await this.$store.dispatch(
        "usersModule/createUserClaim",
        {
          claimType: this.claimType,
          claimValue: this.claimValue,
          valueType: "String",
          userId: this.user.id,
        }
      );
      this.clearData();
      this.dialog = false;
    },
    cancel: function () {
      this.$store.commit("usersModule/clearUser");
      this.clearData();
      this.dialog = false;
    },
    clearData: function () {
      this.claimType = "";
      this.claimValue = "";
    },
  },
};
</script>
