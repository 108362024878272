<template>
  <div>
    <table id="claims-table">
      <thead>
        <tr>
          <th>Claim Type</th>
          <th>Claim Value</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="claim in claims" :key="claim.claimType">
          <td>{{ claim.claimType }}</td>
          <td>{{ claim.claimValue }}</td>
          <td>
            <v-btn text v-on:click="deleteUserClaim(claim)">
              <icon icon="trash-alt" color="primary"></icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["user"],
  computed: {
    claims: function () {
      return this.user.claims;
    },
  },
  methods: {
    deleteUserClaim: function (claim) {
      var claimType = claim.claimType;
      var claimValue = claim.claimValue;
      var userId = this.user.id;
      this.$store.dispatch("usersModule/deleteUserClaim", {
        claimType: claimType,
        claimValue: claimValue,
        userId: userId,
      });
    },
  },
};
</script>
