import mgr from "../../security/security.js";
import * as api from "../Services/api";
import UserProfile from "../../objs/UserProfile";

export const state = {
  isAuthenticated: false,
  user: "",
  requiresAuth: false,
  hasAccessToApp: true,
  userProfile: new UserProfile(),
};

export const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setRequiresAuth(state, payload) {
    state.requiresAuth = payload;
  },
  setIsAuthenticated(state, payload) {
    state.isAuthenticated = payload;
  },
  setHasAccessToApp(state, payload) {
    state.hasAccessToApp = payload;
  },
  setUserProfile(state, payload) {
    let userProfile = new UserProfile({
      id: payload.id,
      userName: payload.userName,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      legacySystemId: payload.legacySystemId,
      roles: payload.roles,
    });

    state.userProfile = userProfile;
  },
};

export const getters = {
  hasUserRole: (state) => (roles) => {
    return (
      roles.filter((role) => state.userProfile.roles.includes(role)).length > 0
    );
  },
  isAdmin: (state, getters) => {
    return getters.hasUserRole(["AdminRole"]);
  },
};

export const actions = {
  async shallowAuthenticate({ commit, state, dispatch }) {
    await mgr
      .signinSilent()
      .then((user) => {})
      .catch((e) => {});

    let user = await mgr.getUser();
    if (!!user) {
      commit("setUser", user);
      dispatch("getUserAccess");
      // load whatever user data is specific to the application here
      if (state.userProfile.id === "") {
        await dispatch("getUserProfile");
      }
      commit("setIsAuthenticated", true);
    } else {
      commit("setIsAuthenticated", false);
    }
  },
  async authenticate({ commit, state, dispatch }, returnPath) {
    commit("uxModule/setShowLoader", true, {
      root: true,
    });
    let user = await mgr.getUser();
    if (!!user) {
      commit("setUser", user);
      dispatch("getUserAccess");
      // load whatever user data is specific to the application here
      if (state.userProfile.id === "") {
        await dispatch("getUserProfile");
      }
      commit("setIsAuthenticated", true);
    } else {
      var signedIn = (await returnPath)
        ? mgr.signinRedirect({ state: returnPath })
        : mgr.signinRedirect();
    }

    commit("uxModule/setShowLoader", false, {
      root: true,
    });
  },
  async logout({ commit, state }) {
    commit("setIsAuthenticated", false);
    await mgr.signoutRedirect();
  },
  async getUserAccess({ commit, state }) {
    return api
      .getUserAccess({
        clientId: mgr.settings.client_id,
        userId: state.user?.profile?.sub,
      })
      .then((response) => {
        if (response.success) {
          commit("setHasAccessToApp", response.data);
        }
      });
  },
  async getUserProfile({ commit, state }) {
    commit("uxModule/setShowLoader", true, {
      root: true,
    });

    let userId = state.user?.profile?.sub;

    await api
      .getUser(userId)
      .then((response) => {
        if (response.success) {
          commit("setUserProfile", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });

    commit("uxModule/setShowLoader", false, {
      root: true,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
