<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on }">
        <v-btn
          title="Manage user roles"
          text
          x-small
          v-on="on"
          v-on:click="activateDialog"
          ><icon icon="users" color="primary"
        /></v-btn>
      </template>

      <v-card class="mx-auto primary--text">
        <v-card-title>Manage User Roles</v-card-title>

        <v-card-text>
          <form-field :field="rolesField" @fieldChange="setRoles"></form-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancel">Cancel</v-btn>
          <v-btn color="primary" text @click="updateUserRoles"
            >Update Roles</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import FormField from "../../objs/Shared/FormField";
import { FieldTypes } from "../../objs/Shared/FieldTypes";

export default {
  props: ["user"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    rolesField: function () {
      return new FormField({
        label: "Add or remove roles",
        value: this.$store.state.usersModule.user.roles,
        componentName: FieldTypes.MULTIPLE_SELECT,
        options: this.$store.state.usersModule.roles,
      });
    },
  },
  methods: {
    activateDialog: function () {
      this.$store.commit("usersModule/setUser", this.user);
      this.dialog = true;
    },
    setRoles: function (event) {
      this.$store.commit("usersModule/setUserRoles", event);
    },
    updateUserRoles: async function () {
      await this.$store.dispatch("usersModule/updateUserRoles");
      this.dialog = false;
    },
    cancel: function () {
      this.$store.commit("usersModule/clearUser");
      this.dialog = false;
    },
  },
};
</script>
