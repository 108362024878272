<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <v-radio-group
      :value="value"
      :label="field.label"
      :disabled="readonly"
      @change="onChange"
      :error-messages="errors"
      color="#005c7b"
    >
      <v-radio
        v-for="(item, index) in field.options"
        :key="index"
        :label="item"
        :value="item"
      ></v-radio>
    </v-radio-group>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "validations"],
  methods: {
    onChange: function (event) {
      this.$emit("fieldChange", event);
    },
  },
};
</script>
