<template>
  <div>
    <v-row>
      <v-col cols="7">
        <label class="primary--text text-body-2 font-weight-bold"
          >User Roles</label
        >
      </v-col>
      <v-col cols="1">
        <clear-filter-button
          @clearFilter="clearRolesFilter"
        ></clear-filter-button>
      </v-col>
    </v-row>
    <v-autocomplete
      :items="roles"
      v-model="selected"
      item-text="name"
      item-value="id"
      v-on:change="update"
      multiple
      small-chips
      dense
      color="primary"
    ></v-autocomplete>
  </div>
</template>

<script>
import ClearFilterButton from "../Shared/clear-filter-button.vue";
export default {
  components: {
    ClearFilterButton,
  },
  data: function () {
    return {
      selected: [],
    };
  },
  computed: {
    roles: function () {
      return this.$store.state.usersModule.roles;
    },
    clearUserRoles: function () {
      return this.$store.state.usersModule.clearUserRoles;
    },
  },
  methods: {
    update: function () {
      this.$store.commit("usersModule/setFilter", {
        filterName: "selectedRoles",
        value: this.selected,
      });
    },
    clearRolesFilter: function () {
      this.selected = [];
      this.$store.commit("usersModule/clearRolesFilter");
    },
  },
  watch: {
    clearUserRoles: function (newVal) {
      this.selected = [];
    },
  },
};
</script>
