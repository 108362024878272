<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn
              title="New Role"
              text
              v-on="on"
              v-on:click="activateDialog"
            >
              <icon icon="plus-circle" color="primary" />
            </v-btn>
          </span>
        </template>
        <span> Add Role</span>
      </v-tooltip>
    </template>
    <validation-observer v-slot="{ invalid }">
      <v-card class="mx-auto primary--text">
        <v-card-title>Assign New Role</v-card-title>
        <v-card-subtitle>to {{ client.name }}</v-card-subtitle>
        <v-card-text>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-autocomplete
              color="primary"
              :items="filteredRoles"
              v-model="form.roleId"
              clearable
              item-text="name"
              item-value="id"
              label="Role"
              :error-messages="errors"
            ></v-autocomplete>
          </validation-provider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancel">Cancel</v-btn>
          <v-btn color="primary" text @click="save" :disabled="invalid"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>
<script>
import ClientRole from "../../objs/ClientRole";

export default {
  data() {
    return {
      form: new ClientRole(),
      dialog: false,
    };
  },
  props: ["clientId"],
  computed: {
    filteredRoles() {
      var roles = this.$store.state.clientModule.roles;

      var rolesForClient = this.client.roles.map((a) => a.id);
      return roles.filter((role) => !rolesForClient.includes(role.id));
    },
    client() {
      return this.$store.getters["clientModule/client"](this.clientId);
    },
  },
  methods: {
    activateDialog() {
      this.form.clientId = this.clientId;

      this.dialog = true;
    },
    async save() {
      const response = await this.$store.dispatch(
        "clientModule/createClientRole",
        { ...this.form }
      );

      var message = response.success
        ? "Successfully assigned role"
        : response.message;

      this.$store.commit("uxModule/setSnackbarMsg", message);
      this.$store.commit("uxModule/setShowSnackbar", true);

      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
    },
  },
};
</script>
