import Home from "../components/HomePage/home";
import Error from "../components/Security/error";
import Callback from "../components/Security/callback";

export const routes = [
  {
    name: "Home",
    path: "/",
    icon: "pen",
    title: "Home",
    component: Home,
    onNavBar: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "Error",
    path: "/error",
    title: "Error",
    component: Error,
    onNavBar: false,
  },
  {
    name: "Callback",
    path: "/callback",
    title: "Callback",
    component: Callback,
    onNavBar: false,
  },
];
