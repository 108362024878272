import { render, staticRenderFns } from "./speeddial.vue?vue&type=template&id=7fdd8500&"
import script from "./speeddial.vue?vue&type=script&lang=js&"
export * from "./speeddial.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports